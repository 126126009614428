import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import store from '@/store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export default {
  install(Vue) {
    const publicHub = new Vue();
    Vue.prototype.$publicHub = publicHub;

    function emit(eventName) {
      return (event, sequenceNumber) => {
        if (process.env.NODE_ENV === "development") {
          /* eslint-disable-next-line no-console */
          console.info('publicHub:', eventName, event, sequenceNumber);
        }
        publicHub.$emit(eventName, event);
        publicHub.$emit('sequenceIncrease', sequenceNumber);
      };
    }

    let connection = null,
      startedPromise = null,
      manuallyClosed = false,
      unwantedDisconnection = false,
      started = false;

    Vue.prototype.startPublicSignalR = () => {
      if (!started) {
        started = true;

        const appInsights = new ApplicationInsights({
          config: {
            connectionString: store.state.app.appSettings.appInsightsConnectionString
          }
        });
        appInsights.loadAppInsights();

        connection = new HubConnectionBuilder()
          .withUrl(store.state.app.appSettings.signalRUrl)
          .withAutomaticReconnect([0, 2000, 10000, 30000, 60000])
          .configureLogging(LogLevel.Warning)
          .build();

        connection.on("onAuctionUpdated", emit('auction-updated'));        
        connection.on("onAuctionRulesUpdated", emit('auction-rules-updated'));
        connection.on("onAuctionVisibilityChanged", emit('auction-visibility-changed'));
        connection.on("onNewHighestBid", emit('new-highest-bid'));
        connection.on("onBidCanceled", emit('bid-canceled'));
        connection.on("onDonationCanceled", emit('donation-canceled'));
        connection.on("onParticipantsStatusChanged", emit('participants-status-changed'));
        connection.on("onUserDeleted", emit('user-deleted'));
        connection.on("onInvoiceUpdated", emit('invoice-updated'));
        connection.on("onInvoiceStatusUpdated", emit('invoice-status-updated'));
        connection.on("onItemReassigned", emit('item-reassigned'));
        connection.on("onItemsIsFeaturedChanged", emit('items-feature-changed'));
        connection.on("onItemAvailabilityChanged", emit('item-availability-changed'));
        connection.on("onItemsAvailabilityChanged", emit('items-availability-changed'));
        connection.on("onItemsIsVisibleChanged", emit('items-visible-changed'));
        connection.on("onItemsCategoryChanged", emit('items-category-changed'));
        connection.on("onItemCategoriesListUpdatedEvent", emit('item-categories-list-updated'));
        connection.on("onItemDeleted", emit('item-deleted'));
        connection.on("onItemsDeleted", emit('items-deleted'));
        connection.on("onItemsLocationChanged", emit('items-location-changed'));
        connection.on("onItemLocationsListUpdatedEvent", emit('item-locations-list-updated'));
        connection.on("onPartnersCategoryChanged", emit('partners-category-changed'));
        connection.on("onPartnerCategoriesListUpdatedEvent", emit('partner-categories-list-updated'));
        connection.on("onPartnerDeleted", emit('partner-deleted'));
        connection.on("onPartnersDeleted", emit('partners-deleted'));
        connection.on("onPartnersListUpdatedEvent", emit("partners-updated"));
        connection.on("onCategoryUpdatedEvent", emit("category-updated"));
        connection.on("onInvoicesPaymentAndStatusChanged", emit('invoices-payment-status-changed'));       
        connection.on("onInvoicesReminderPaymentChanged", emit('invoices-reminder-payment-changed'));
        connection.on("onInvoicesStatusError", emit('invoices-status-error'));

        function start() {
          startedPromise = connection.start()
            .then(() => {
              if (unwantedDisconnection) {
                publicHub.$emit('reconnected');
                unwantedDisconnection = false;
              }
            })
            .catch(err => {
              /* eslint-disable-next-line no-console */
              console.error('Failed to connect with hub', err);
              return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
            });
          return startedPromise;
        }

        connection.onreconnecting(error => {
          if (appInsights) {
            appInsights.trackEvent({
              name: 'SignalrEvent',
              properties: {
                errorMessage: error,
                reason: "Signalr reconnecting"
              }
            });
          }
          store.state.app.signalRConnectionFail = true;
        });

        connection.onclose(error => {
          if (!manuallyClosed) {
            unwantedDisconnection = true;
            if (appInsights) {
              appInsights.trackEvent({
                name: 'SignalrEvent',
                properties: {
                  errorMessage: error,
                  reason: "Signalr disconnected"
                }
              });
            }           
          }
        });

        manuallyClosed = false;
        start();
      }
    };

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) {
        return;
      }

      manuallyClosed = true;
      return startedPromise
        .then(() => connection.stop())
        .then(() => startedPromise = null);
    };

    publicHub.joinAuctionGroup = (auctionId) => {
      if (!startedPromise) {
        return;
      }

      return startedPromise
        .then(() => connection.invoke('joinAuctionGroup', auctionId))
        .catch(console.error); /* eslint-disable-line no-console */
    };
  }
};

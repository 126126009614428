import axios from 'axios';
import Vue from 'vue';

const setBidDates = (bids) => {
  for (const bid of bids) {
    bid.createdOn = new Date(bid.createdOn);
  }
};

export function getItemsBidsAsync(context, itemIds) {
  return new Promise(async (resolve, reject) => {
    try {
      const bidRequest = (await axios.post(`/api/auction/items/bids`, itemIds)).data;

      for (const itemId of itemIds) {
        const item = context.state.items[itemId];
        if (item) {
          if (bidRequest[item.id]) {
            setBidDates(bidRequest[item.id]);
            Vue.set(item, 'bids', bidRequest[item.id]);
          }
          Vue.set(item, 'bidsLoaded', true);
        }
      }

      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

import axios from 'axios';
import State from '@/store/user/state.js';
import { dumbAction } from '@/store/util.js';
import i18n from '@/i18n';

export default {
  async getCurrentUser(context) {
    let response = null;

    // En cas d'échec (401), redirection automatique au login via un intercepteur

    if (context.rootState.auction.auction != null) {
      response = (await axios.get(`/api/user/auction`)).data;
    } else {
      const slug = window.location.pathname.split('/')[2];

      if (slug != i18n.t('routes.signup.base') &&
        slug != i18n.t('routes.organization.base') &&
        slug != i18n.t('routes.termsOfUse') &&
        slug != i18n.t('routes.privacyPolicy') &&
        slug != i18n.t('routes.supplier') &&
        slug != i18n.t('routes.itemsCatalogueAuction') &&
        slug != i18n.t('routes.itemsCatalogue')) {
        response = (await axios.get(`/api/user/${slug}`)).data;
      } else if (context.rootState.organization.organization && context.rootState.organization.organization.id) {
        response = (await axios.get(`/api/user/organization`)).data;
      } else {
        response = (await axios.get(`/api/user`)).data;
      }

      if (!response) {
        throw null;
      }
    }

    context.commit('setCurrentUser', response);

    return response;
  },
  async reset(context, callback) {
    context.state = new State();
    callback();
  },
  async getParticipants(context) {
    if (!context.state.getParticipantsStarted) {
      try {
        context.state.getParticipantsStarted = true;
        const response = await axios.get(`/api/auction/users/assistant/participants`, { params: { includeInactive: false } });
        context.commit('setParticipants', response.data);
      } catch (error) {
        context.commit('app/addError', error, { root: true });
      } finally {
        context.commit('setParticipantsRetrieved', true);
      }
    }
  },
  async getUserFollowedItems(context)
  {
    try {
      const { data } = await axios.get('/api/users/followed-items/find');
      context.commit('setUserFollowedItems', data.followedItems);
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async makeUserFollowItem(context, { itemId, userId, callback }) {
    try {
      await axios.post(`/api/users/${userId}/followed-items/${itemId}`);
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    } finally {
      if (callback) {
        callback();
      }
    }
  },
  async startFollowingItem(context, { itemId, callback }) {
    try {
      await axios.post(`/api/users/followed-items/${itemId}`);
      context.commit('startFollowing', itemId);
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    } finally {
      if (callback) {
        callback();
      }
    }
  },
  async stopFollowingItem(context, { itemId, callback }) {
    try {
      await axios.delete(`/api/users/followed-items/${itemId}`);
      context.commit('stopFollowing', itemId);
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    } finally {
      if (callback) {
        callback();
      }
    }
  },
  async getUsersInvitationsList(context, { role, callback }) {
    try {
      callback((await axios.post(`/api/users/invitation/find`, null, { params: { role } })).data);
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async saveProfile(context, { profile, onSuccess, onError }) {
    try {
      const updated = (await axios.post('/api/users/profile', profile)).data;
      context.commit('setCurrentUser', updated);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      if (typeof onError === 'function') {
        onError(error.response.data);
      } else {
        context.commit('app/addError', error, { root: true });
      }
    }
  },
  async deleteInvitation(context, { invitationId, callback }) {
    try {
      const response = await axios.delete(`/api/invitation/${invitationId}`);
      if (response.data) {
        if (callback) {
          callback();
        }
      } else {
        context.commit('app/addError', { message: 'The invitation could not be deleted' }, { root: true });
      }
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async bulkDeleteInvitations(context, { invitationIds, callback }) {
    try {
      const { data, status } = await axios.delete('/api/invitation/bulk', { data: invitationIds });
      if (status === 200) {
        if (typeof callback === 'function') {
          callback(data);
        }
      } else {
        context.commit('app/addError', { message: 'Invitations could not be deleted' }, { root: true });
      }
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async setUsersStatus(context, { userIds, isActive, done }) {
    try {
      const { status } = await axios.put('/api/auction/users', userIds, { params: { isActive } });

      if (status === 200) {
        if (typeof done === 'function') {
          done(isActive);
        }
      } else {
        context.commit('app/addError', { message: 'User status could not be updated' }, { root: true });
      }
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getInvoices(context) {
    try {
      const { data } = await axios.get('/api/payment/user/invoices');
      context.commit('setInvoices', data);
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getDonatedAmount(context) {
    try {
      const response = (await axios.get('/api/auction/user/donatedAmount')).data;
      context.commit('setDonatedAmount', response);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async ManageUserParticipations(context) {
    try {
      const { data: response } = await axios.get('/api/user/participations');
      context.commit('setCurrentUser', response.user);
      context.commit('setHasParticipation', response.hasParticipation);

      return response.firstConnection;
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async updateHasDiscoveredSolicitingItemDonations(context, { hasDiscoveredSolicitingItemDonations }) {
    try {
      await axios.post(`/api/user/discovered-soliciting-item-donations-toast`, { hasDiscoveredSolicitingItemDonations: hasDiscoveredSolicitingItemDonations });
      context.commit('setHasDiscoveredSolicitingItemDonations', hasDiscoveredSolicitingItemDonations);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getUserViewedModals(context) {
    try {
      const response = (await axios.get('/api/user-viewed-modal/get-auction-modals-stripe')).data;

      context.commit('setUserViewedModals', response);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async upsertUserPaymentInfo(context, { fullName, email, paymentMethodId }) {
    try {
      const { data: response } = await axios.post('/api/user/upsert-payment-info', {
        fullName: fullName,
        email: email,
        paymentMethodId: paymentMethodId
      });

      context.commit('setUserPaymentInfo', response.paymentInfo);
      context.commit('setHasCardRegister', response.isCardValid);
      return response.isCardValid;
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async updateUserPaymentInfoWantsBearFees(context, { wantsBearFees }) {
    try {
      await axios.post('/api/user/update-payment-info-wants-bear-fees', { wantsBearFees: wantsBearFees });
      context.commit('setUserPaymentInfoWantsBearFees', wantsBearFees);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async deleteUserPaymentInfo(context) {
    try {
      await axios.post('/api/user/delete-payment-info');
      context.commit('setUserPaymentInfo', null);
      context.commit('setHasCardRegister', false);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getUserPaymentInfo(context) {
    try {
      const { data: response } = await axios.get('/api/user/get-payment-info');
      context.commit('setUserPaymentInfo', response);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async addUserViewedAuctionModal(context, { modalType }) {
    try {
      await axios.post('/api/user-viewed-modal/add-auction-modals', null, { params: { modalType } });
      context.commit('updateUserViewedModals', modalType);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  ...dumbAction('connectionIdUpdated'),
  ...dumbAction('setShowModifyPhoneNumberToastSuccess'),
  ...dumbAction('setShowAddPhoneNumberToastSuccess')
};

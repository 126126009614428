import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import store from '@/store';
import VueCookie from 'vue-cookie';
import { authenticationCookieName, refreshCookieName } from '@/scripts/Security.js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export default {
  install(Vue) {
    const authenticatedHub = new Vue();
    Vue.prototype.$authenticatedHub = authenticatedHub;

    function emit(eventName) {
      return event => {
        if (process.env.NODE_ENV === "development") {
          /* eslint-disable-next-line no-console */
          console.info('authenticatedHub:', eventName, event);
        }
        authenticatedHub.$emit(eventName, event);
      };
    }

    let connection = null,
      startedPromise = null,
      manuallyClosed = false,
      started = false;

    Vue.prototype.startAuthSignalR = () => {
      function start() {
        startedPromise = connection.start()
          .catch(() => {
            const token = VueCookie.get(authenticationCookieName);
            const refreshToken = VueCookie.get(refreshCookieName);
            if (!token && !refreshToken) {
              //S'il n'y a pas de token ni de refreshtoken, on cesse les essais de reconnexion. le Signalr authentifi� sera reconnect� � la prochaine connexion
              return;
            }

            return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
          });
        return startedPromise;
      }

      if (!started) {
        started = true;

        const appInsights = new ApplicationInsights({
          config: {
            connectionString: store.state.app.appSettings.appInsightsConnectionString
          }
        });
        appInsights.loadAppInsights();

        connection = new HubConnectionBuilder()
          .withUrl(
            store.state.app.appSettings.authSignalRUrl,
            {
              accessTokenFactory: () => VueCookie.get(authenticationCookieName)
            }
          )
          .withAutomaticReconnect([0, 2000, 10000, 30000, 60000])
          .configureLogging(LogLevel.Warning)
          .build();

        connection.on("onNewHighestBidResult", emit('highest-bid-result'));
        connection.on("onEqualBidEvent", emit('equal-bid'));
        connection.on("onOutbidBidEvent", emit('outbid-bid', event));
        connection.on("onItemUnavailableForBidsEvent", emit('item-unavailable', event));
        connection.on("onChatroomMessage", emit('chatroom-message', event));
        connection.on("onMultipleChatroomMessages", emit('multiple-chatroom-messages', event));
        connection.on("onUserSmsOptOutChanged", emit('participant-smsoptout-changed', event));
        connection.on("onDonationPayNowEvent", emit('donation-pay-now'));
        connection.on("onNewInvoiceAvailable", emit('new-invoice-available', event));
        connection.on("onNewInvoiceCredit", emit('new-invoice-credit', event));
        connection.on("onNewInvoiceRefund", emit('new-invoice-refund', event));
        connection.on("onRefundResponseReceived", emit('refund-response-received'));
        connection.on("onForceDisconnection", emit('force-disconnection', event));
        connection.on("onErroneousItemTypeForBidsEvent", emit('erroneous-item-type-for-bid', event));
        connection.on("onAuctionPaymentSuccess", emit('payment-success', event));
        connection.on("onAuctionPaymentFailure", emit('payment-failure', event));
        connection.on("onApplyAuctionPaymentEvent", emit('apply-payment-event', event));

        connection.onreconnecting(error => {
          if (appInsights) {
            appInsights.trackEvent({
              name: 'SignalrEvent',
              properties: {
                errorMessage: error,
                reason: "Signalr reconnecting"
              }
            });
          }
          store.state.app.signalRConnectionFail = true;
        });

        connection.onclose(error => {
          if (!manuallyClosed) {
            if (appInsights) {
              appInsights.trackEvent({
                name: 'SignalrEvent',
                properties: {
                  errorMessage: error,
                  reason: "Signalr disconnected"
                }
              });
            }          
          }
        });       

        manuallyClosed = false;
        start();
      }
    };

    Vue.prototype.stopAuthSignalR = () => {
      if (!startedPromise) {
        return;
      }

      manuallyClosed = true;
      return startedPromise
        .then(() => connection.stop())
        .then(() => startedPromise = null);
    };

    authenticatedHub.joinAuctionGroup = (auctionId) => {
      if (!startedPromise) {
        return;
      }
      return startedPromise
        .then(() => connection.invoke('joinAuctionGroup', auctionId))
        .catch(console.error); /* eslint-disable-line no-console */
    };
  }
};

import { BID_TIMEOUT } from '@/constants.js';
import { itemSortingMethods as ItemSortingMethods } from '@/GeneratedModels/ItemSortingMethods.js';
import { AuctionStatus } from '@/store/auction/state.js';
import RequestBuffer from '@/store/RequestBuffer.js';
import { dumbAction } from '@/store/util.js';
import axios from 'axios';
import { getItemsBidsAsync } from '../../scripts/itemsUtils';

let itemBuffer = null;
function createBuffer(context) {
  itemBuffer = new RequestBuffer(async buffer => {
    try {
      const requestedItemIds = buffer.requests;
      const response = (await axios.post(`/api/auction/items/find`, Object.keys(requestedItemIds))).data;
      context.commit('setItems', response);
      response.forEach(item => {
        requestedItemIds[item.id].forEach(options => {
          if (options && typeof options.callback === 'function') {
            options.callback(item);
          }
        });
      });
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  }, 200);
}

export default {
  async getCurrentAuction(context) {
    try {
      const slug = window.location.pathname.split('/')[2],
        { data: response } = await axios.get(`/api/auction/slug/${slug}`);

      context.commit('setCurrentAuction', response);
      return response;
    } catch (error) {
      if (error.response.status != 404) {
        context.commit('app/addError', error, { root: true });
      }
      else {
        throw error;
      }
    }
  },
  async getCurrentAuctionByOrganization(context) {
    try {
      const { data: response } = await axios.get(`/api/organization/organization-auction`);

      if (response) {
        context.commit('setCurrentAuction', response);
      }

      return response;
    }
    catch (error) {
      if (error.response.status != 404) {
        context.commit('app/addError', error, { root: true });
      }
    }
  },
  async getCurrentAdminAuctionByUser(context) {
    try {
      const { data: response } = await axios.get(`/api/user-admin-auction`);

      if (response) {
        context.commit('setCurrentAuction', response);
      }

      return response;
    }
    catch (error) {
      if (error.response.status != 404) {
        context.commit('app/addError', error, { root: true });
      }
    }
  },
  async searchItems(context, { offset, maxCount, sortMethod, isReversed, searchCriteria, categoryIds, locationIds, partnerIds, locale, callback, cancelToken }) {
    try {
      const params = { sortMethod, isReversed, searchCriteria, categoryIds, locationIds, partnerIds, locale };
      if (offset !== undefined) {
        params.offset = offset;
      }
      if (maxCount !== undefined) {
        params.maxCount = maxCount;
      }
      const { data: { data: items, totalCount } } = await axios.post(`/api/auction/items/search`, params, { cancelToken });
      context.commit('setItems', items);
      if (callback) {
        const ids = [];
        for (const i in items) {
          ids.push(items[i].id);
        }

        try {
          const itemIds = [];
          for (const i in items) {
            itemIds.push(items[i].id);
          }
          // On va chercher les mises avec un autre appel async.
          getItemsBidsAsync(context, itemIds);
        } catch (error) {
          context.commit('app/addError', error, { root: true });
        }

        callback(ids, totalCount, offset, categoryIds);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        context.commit('app/addError', error, { root: true });
      }
    }
  },
  async searchItemNeighbors(context, { id, sortMethod, isReversed, searchCriteria, categoryIds, locationIds, partnerIds, locale, callback, cancelToken }) {
    try {
      const params = { sortMethod, isReversed, searchCriteria, categoryIds, locationIds, partnerIds, locale };
      const { data: { data: item, previous, next } } = await axios.post(`/api/auction/items/${id}/search/neighbors`, params, { cancelToken });
      context.commit('setItems', [item]);
      if (typeof callback === 'function') {
        callback(item, previous, next);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        context.commit('app/addError', error, { root: true });
      }
    }
  },
  async getItems(context, { offset, maxCount, withBids, cancelToken = null }) {
    const params = { sortMethod: ItemSortingMethods.SortByNumber, isReversed: false };
    if (offset !== undefined) {
      params.offset = offset;
    }
    if (maxCount !== undefined) {
      params.maxCount = maxCount;
    }
    if (withBids !== undefined) {
      params.withBids = withBids;
    }

    const { data: { data: items } } = await axios.post(`/api/auction/items/search`, params, { cancelToken });
    context.commit('setItems', items);
  },
  async findItemsById(context, itemIds) {
    if (!itemBuffer) {
      createBuffer(context);
    }
    itemBuffer.requestMany(itemIds);
  },
  searchTrackItems(context, { search, sort }) {
    axios.post(`/api/auction/items/trackSearchFollowedItems`, { search: search, sort: sort });
  },
  adminLotsSearched(context, { query, lotCount }) {
    axios.post(`/api/auction/items/trackAdminLotsSearched`, { query: query, lotCount: lotCount });
  },
  adminLotsFiltered(context, { filterType, lotCount }) {
    axios.post(`/api/auction/items/trackAdminLotsFiltered`, { filterType: filterType, lotCount: lotCount });
  },
  async getItem(context, { id, callback, immediate }) {
    if (!itemBuffer) {
      createBuffer(context);
    }
    itemBuffer.requestOne(id, { callback }, immediate);
  },
  async getFeaturedItems(context, { callback, getRandomItemsIfNoFeaturedItemsFound = false, numberOfNonFeaturedItems = 4 }) {
    try {
      const params = { getRandomItemsIfNoFeaturedItemsFound, numberOfNonFeaturedItems };
      const response = (await axios.get(`/api/auction/items/featured`, { params })).data.filter(x => !context.state.items[x.id]);
      context.commit('setItems', response);

      try {
        const itemIds = response.map(x => x.id);
        getItemsBidsAsync(context, itemIds);
      } catch (error) {
        context.commit('app/addError', error, { root: true });
      }

      if (callback) {
        callback();
      }
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async deleteItem(context, { itemId, callback }) {
    try {
      const response = await axios.delete(`/api/auction/items/${itemId}`, { id: itemId });
      if (response.data) {
        context.commit('deleteItem', itemId);
        if (callback) {
          callback();
        }
      } else {
        context.commit('app/addError', { message: 'The item could not be deleted' }, { root: true });
      }
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async deleteItems(context, { itemIds, callback }) {
    try {
      const response = await axios.delete(`/api/auction/items/bulkDelete`, { data: { ids: itemIds } });
      if (response.data) {
        context.commit('deleteItems', itemIds);
        if (callback) {
          callback();
        }
      } else {
        context.commit('app/addError', { message: 'The items could not be deleted' }, { root: true });
      }
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async placeSymbolicBid(context, { auctionId, userId, itemId, amount }) {
    try {
      const response = await axios.post(context.rootState.app.appSettings.symbolicBidFunctionUrl, {
        auctionId,
        userId,
        itemId,
        amount
      });
      const timeoutId = setTimeout(() => {
        context.commit('failedBidEvent', { itemId, amount });
      }, BID_TIMEOUT);

      context.commit('storeCorrelationId', { itemId, correlationId: response.data, timeoutId });
    } catch (e) {
      context.commit('failedBidEvent', { itemId });
      if (e.response) {
        switch (+e.response.status) {
          case 410: // encan fermé
          case 403: // participant inactif ou avec rôle inadéquat
          case 401: // participant n'a pas acces
            // Ce n'est pas une erreur de l'application
            break;
          default:
            context.commit('app/addError', e, { root: true });
            break;
        }
      }
    }
  },
  async placeBid(context, { auctionId, userId, itemId, amount }) {
    try {
      const response = await axios.post(context.rootState.app.appSettings.bidFunctionUrl, {
        auctionId,
        userId,
        itemId,
        amount
      });

      const timeoutId = setTimeout(() => {
        context.commit('failedBidEvent', { itemId, amount });
      }, BID_TIMEOUT);

      context.commit('storeCorrelationId', { itemId, correlationId: response.data, timeoutId });
    } catch (e) {
      context.commit('failedBidEvent', { itemId });
      if (e.response) {
        switch (+e.response.status) {
          case 410: // encan fermé
          case 403: // participant inactif ou avec rôle inadéquat
          case 401: // participant n'a pas acces
            // Ce n'est pas une erreur de l'application
            break;
          default:
            context.commit('app/addError', e, { root: true });
            break;
        }
      }
    }
  },
  async getPartners(context, { onSuccess, always } = {}) {
    try {
      const { data: response } = await axios.get(`/api/auction/partners`);

      if (typeof onSuccess === 'function') {
        onSuccess(response);
      }
      return response;
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    } finally {
      if (typeof always === 'function') {
        always();
      }
    }
  },
  async getFeaturedPartners(context, { onSuccess, always } = {}) {
    try {
      const { data: response } = await axios.get(`/api/auction/partners/featured`);

      if (typeof onSuccess === 'function') {
        onSuccess(response);
      }
      return response;
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    } finally {
      if (typeof always === 'function') {
        always();
      }
    }
  },
  async deletePartner(context, { partnerId, callback }) {
    try {
      const response = await axios.delete(`/api/auction/partners/${partnerId}`);
      if (response.data) {
        if (callback) {
          callback();
        }
      } else {
        context.commit('app/addError', { message: 'The partner could not be deleted' }, { root: true });
      }
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async deletePartners(context, { partnerIds, callback }) {
    try {
      const response = await axios.delete(`/api/auction/partners/bulk`, { data: { ids: partnerIds } });
      if (response.data) {
        if (callback) {
          callback();
        }
      } else {
        context.commit('app/addError', { message: 'The partners could not be deleted' }, { root: true });
      }
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async deletePartnerCategory(context, { partnerCategoryId, partnerCategoryToChangeId, callback }) {
    try {
      const response = await axios.delete(`/api/auction/partners/category/${partnerCategoryId}`, { params: { partnerCategoryToChangeId } });
      if (response.data) {
        if (callback) {
          callback();
        }
      } else {
        context.commit('app/addError', { message: 'The partner category could not be deleted' }, { root: true });
      }
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getPartnerCategories(context, { onSuccess } = {}) {
    try {
      const response = (await axios.get('/api/auction/partners/categories')).data;

      if (typeof onSuccess === 'function') {
        onSuccess(response);
      }
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getCategoriesUserStatus(context, { onSuccess } = {}) {
    try {
      const response = (await axios.get('/api/auction/partners/getCategoriesUserStatus')).data;

      if (typeof onSuccess === 'function') {
        onSuccess(response);
      }
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async savePartnerCategories(context, { categories, callback }) {
    try {
      await axios.post('/api/auction/partners/categories', categories);
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getItemCategories(context) {
    try {
      return (await axios.get('/api/auction/items/categories')).data;
    } catch (error) {
      context.commit('app/addError', error, { root: true });
      throw error;
    }
  },
  async getItemLocations(context) {
    try {
      return (await axios.get('/api/auction/items/locations')).data;
    } catch (error) {
      context.commit('app/addError', error, { root: true });
      throw error;
    }
  },
  async getLastImpersonatedUsers(context) {
    if (!context.state.getLastImpersonatedUsersStarted) {
      try {
        context.state.getLastImpersonatedUsersStarted = true;
        const response = await axios.get(`/api/auction/users/${context.rootState.user.user.id}/bidAsUsers`);
        context.commit('setLastImpersonatedUsers', response.data);
      } catch (error) {
        context.commit('app/addError', error, { root: true });
      } finally {
        context.commit('setLastImpersonatedUsersRetrieved', true);
      }
    }
  },
  async getHighestBids(context) {
    try {
      const response = await axios.get(`/api/auction/highestBids`);
      context.commit('setHighestBids', response.data);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async setLastImpersonatedUser(context, userId) {
    const userIndex = context.state.lastImpersonatedUsers.indexOf(userId);
    if (userIndex >= 0) {
      context.state.lastImpersonatedUsers.splice(userIndex, 1);
      context.state.lastImpersonatedUsers.unshift(userId);
    } else {
      context.state.lastImpersonatedUsers.unshift(userId);
      if (context.state.lastImpersonatedUsers.length > 3) {
        context.state.lastImpersonatedUsers.length = 3;
      }
    }
  },
  async cancelBid(context, { correlationId, location, callback }) {
    try {
      await axios.delete(`/api/auction/bids`, { params: { correlationId: correlationId, location: location } });
      if (callback) {
        callback();
      }
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async placeDonation(context, { userId, amount, payNow }) {
    try {
      await axios.post("/api/auction/placeDonation", {
        userId,
        amount,
        payNow
      });
      if (!payNow) {
        context.commit('successDonationEvent', { userId });
      }

      const donatedAmount = context.rootState.user.donatedAmount + amount;
      context.commit('user/setDonatedAmount', donatedAmount, { root: true });

      //Si l'encan est terminé, le montant amassé est affiché donc on le met au moins à jour pour le user courant
      if (context.state.status >= AuctionStatus.over) {
        context.dispatch('getRaisedAmount');
      }
    } catch (e) {
      context.commit('failedDonationEvent', { userId });

      if (e.response) {
        switch (+e.response.status) {
          case 410: // encan fermé
          case 403: // participant inactif ou avec rôle inadéquat
          case 401: // participant n'a pas acces
            // Ce n'est pas une erreur de l'application
            break;
          default:
            context.commit('app/addError', e, { root: true });
            break;
        }
      }
    }
  },
  async getDonations(context, { cancelToken = null }) {
    const { data } = await axios.get(`/api/auction/donations`, { cancelToken });
    context.commit('setDonations', data);

    return data;
  },
  async cancelDonation(context, { correlationId, callback }) {
    try {
      await axios.delete(`/api/auction/donations/${correlationId}`);
      if (callback) {
        callback();
      }
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async itemReassigned(context, itemId) {
    if (!itemBuffer) {
      createBuffer(context);
    }
    itemBuffer.requestOne(itemId, {
      callback: (item) => {
        if (typeof context.state.itemReassignmentListeners[item.id] === 'function') {
          context.state.itemReassignmentListeners[item.id]();
        }
      }
    }, true);
    context.commit('addItemNotificationSuccess', itemId);
  },
  async getInvoices(context) {
    const { data } = await axios.get('/api/payment/auction/invoices');
    context.commit('setInvoices', data);
  },
  async getInvoiceById(context, invoiceId) {
    try {
      const response = (await axios.get(`/api/payment/invoice/${invoiceId}`)).data;
      context.commit('setInvoice', { invoiceId, invoice: response });
      context.commit('user/setInvoice', response, { root: true });
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getPrintInvoiceById(context, invoiceId) {
    try {
      const response = (await axios.get(`/api/payment/printInvoice/${invoiceId}`)).data;
      context.commit('setInvoice', { invoiceId, invoice: response });
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getRefundResponses(context) {
    try {
      const response = (await axios.get('/api/payment/auction/refundresponses')).data;
      context.commit('setRefundResponses', response);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async changeVisibility(context, { visible }) {
    try {
      const response = (await axios.post(`/api/auction/visibility/${visible}`)).data;
      context.commit('setCurrentAuction', response);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getRaisedAmount(context) {
    try {
      const { data } = await axios.get('/api/auction/raisedAmount');
      context.commit('setRaisedAmount', data);
    } catch (error) {
      // Ne pas rediriger vers la page d'erreur
      if (process.env.NODE_ENV === 'development') {
        console.error(error); /* eslint-disable-line no-console */
      }
    }
  },
  async saveAllowStripePayment(context, { auctionId, allowStripeCheckoutPayment }) {
    try {
      await axios.post(`/api/auction/allowStripeCheckoutPayment/Save`, null, { params: { auctionId: auctionId, allowStripeCheckoutPayment: allowStripeCheckoutPayment } });
      context.commit('setAllowStripeCheckoutPayment', allowStripeCheckoutPayment);
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async saveTransactionFeesPlanType(context, { transactionFeesPlanType }) {
    try {
      await axios.post(`/api/auction/transactionFeesPlanType/Save`, null, { params: { transactionFeesPlanType: transactionFeesPlanType } });
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async saveCreditCardRequired(context, { creditCardRequired }) {
    try {
      await axios.post(`/api/auction/creditCardRequired/Save`, null, { params: { creditCardRequired: creditCardRequired } });
      context.commit('creditCardRequired', creditCardRequired);
    } catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  async getAuctionRules(context) {
    try {
      const response = (await axios.get('/api/auction/rules')).data;
      context.commit('auctionRulesUpdated', response);
    }
    catch (error) {
      context.commit('app/addError', error, { root: true });
    }
  },
  itemsAvailabilityChangedEvent(context, event) {
    for (const data of event) {
      context.commit('itemAvailabilityChanged', data);
    }
  },
  itemsIsVisibleChangedEvent(context, event) {
    for (const data of event) {
      context.commit('itemIsVisibleChanged', data);
    }
  },
  itemsCategoryChangedEvent(context, event) {
    for (const data of event) {
      context.commit('itemCategoryChanged', data);
    }
  },
  itemsLocationChangedEvent(context, event) {
    for (const data of event) {
      context.commit('itemLocationChanged', data);
    }
  },
  invoicesPaymentStatusChanged(context, event) {
    for (const data of event) {
      context.commit('invoicePaymentStatusChanged', data);
    }
  },
  invoicesReminderPaymentChanged(context, event) {
    for (const data of event) {
      context.commit('invoiceReminderPaymentChanged', data);
    }
  },
  invoicesStatusError(context, event) {
    for (const data of event) {
      context.commit('invoiceStatusError', data);
    }
  },
  async findItemByIds(context, { itemIds }) {
    const { data: response } = await axios.post('/api/auction/items/find', itemIds);

    context.commit('setItems', response);
  },
  invoiceStatusUpdatedEvent(context, data) {
    context.commit('invoiceStatusUpdatedEvent', data);
    context.commit('user/setInvoiceStatus', data, { root: true });
  },
  ...dumbAction('setShowCategoryFilters'),
  ...dumbAction('setShowLocationFilters'),
  ...dumbAction('setShowPartnerFilters'),
  itemDeletedEvent(context, itemId) {
    context.commit('deleteItem', itemId);
  },
  itemsDeletedEvent(context, itemIds) {
    context.commit('deleteItems', itemIds);
  },
  ...dumbAction('setLastImpersonatedUsers'),
  ...dumbAction('participantsStatusChanged'),
  userDeleted(context, userId) {
    context.commit('participantsStatusChanged', { userIds: [userId], status: false });
  },
  ...dumbAction('auctionUpdated'),
  ...dumbAction('endDateUpdated'),
  ...dumbAction('clearNotification'),
  ...dumbAction('outbidBidEvent'),
  ...dumbAction('highestBidEvent'),
  ...dumbAction('itemUnavailableForBidsEvent'),
  ...dumbAction('authHighestBidEvent'),
  ...dumbAction('equalBidEvent'),
  ...dumbAction('bidCanceledEvent'),
  ...dumbAction('donationCanceledEvent'),
  ...dumbAction('donationPayNowEvent'),
  ...dumbAction('itemAvailabilityChanged'),
  ...dumbAction('itemsIsVisibleChanged'),
  ...dumbAction('auctionVisibilityChanged'),
  ...dumbAction('erroneousItemTypeForBidEvent'),
  ...dumbAction('auctionPaymentSuccess'),
  ...dumbAction('auctionPaymentFailure'),
  ...dumbAction('auctionPaymentEvent'),
  ...dumbAction('itemsIsFeatureChangedEvent'),
  ...dumbAction('auctionRulesUpdated')
};

import axios from 'axios';
import { Validator } from 'vee-validate';
import store from '@/store';

const serverSidePhoneNumber = {
  async validate(value) {
    let valid = false;
    try {
      valid = (await axios.get(`/api/validate/phone`, { params: { value } })).data;
    } catch (error) {
      store.dispatch('app/addError', error);
    }
    return { valid };
  }
};

const itemNumberExists = {
  async validate(value, itemId) {
    let valid = false;
    if (!value) { return true; }
    try {
      valid = !(await axios.get(`/api/validate/item/${value}/${itemId ? itemId : ``}`)).data;
    } catch (error) {
      store.dispatch('app/addError', error);
    }
    return { valid };
  }
};

const phoneNumberExists = {
  async validate(value, userId) {
    let valid = false;
    if (!value) { return true; }
    try {
      valid = !(await axios.get(`/api/validate/user/phoneNumber/${value}/${userId ? userId : ``}`)).data;
    } catch (error) {
      store.dispatch('app/addError', error);
    }
    return { valid };
  }
};

const emailExists = {
  async validate(value, userId) {
    let valid = false;
    if (!value) { return true; }
    try {
      valid = !(await axios.post(`/api/validate/user/email`, {
        email: value,
        userId: userId[0] ? userId[0] : ''
      })).data;
    } catch (error) {
      store.dispatch('app/addError', error);
    }
    return { valid };
  }
};

const usernameExistsForAuction = {
  async validate(value) {
    let valid = false;
    if (!value) { return true; }
    try {
      valid = !(await axios.get(`/api/validate/user/auction/email`, { params:{ value } })).data;
      if (!valid) {
        valid = !(await axios.get(`/api/validate/user/auction/phoneNumber/${value}`)).data;
      }
    } catch (error) {
      store.dispatch('app/addError', error);
    }
    return { valid };
  }
};

const auctionSlugExists = {
  async validate(value) {
    let valid = false;
    if (!value) { return true; }
    try {
      valid = !(await axios.get(`/api/validate/auction/slug`, { params: { value } })).data;
    } catch (error) {
      store.dispatch('signup/addError', error);
    }
    return { valid };
  }
};

const uniqueUserPhoneNumber = {
  async validate(value, userId) {
    let valid = false;
    if (!value) { return true; }
    try {
      valid = !(await axios.get(`/api/validate/unique/userPhoneNumber/${value}/${userId}`)).data;
    } catch (error) {
      store.dispatch('app/addError', error);
    }
    return { valid };
  }
};

export default Object.freeze({
  register() {
    Validator.extend('serverSidePhoneNumber', serverSidePhoneNumber);
    Validator.extend('itemNumberExists', itemNumberExists);
    Validator.extend('phoneNumberExists', phoneNumberExists);
    Validator.extend('emailExists', emailExists);
    Validator.extend('usernameExistsForAuction', usernameExistsForAuction);
    Validator.extend('auctionSlugExists', auctionSlugExists);
    Validator.extend('uniqueUserPhoneNumber', uniqueUserPhoneNumber);
  }
});

export default {
  'en-CA': {
    messages: {
      _default: 'Invalid value',
      required: () => 'This field is required.',
      email: () => 'Invalid email adress.',
      not_in: () => 'This field is required.',
      min_value: () => `Enter a higher amount.`,
      max_value: () => `Enter a lower amount.`,
      numeric: () => 'Please enter a whole number.',
      phone: () => 'Invalid phone number format.',
      postalCode: () => 'Please specify a valid postal code.',
      falsy: () => 'The value has to be No',
      min: (field, [length]) => `The field ${field} must contain at least ${length} characters.`,
      max: (field, [length]) => `The field ${field} must contain no more than ${length} characters.`,
      integer_if: () => 'Fractional values are not accepted for this product',
      confirmed: 'The value of both fields is not the same.',
      duplicate: (field) => `The value of ${field} is already used and must be unique`,
      url: 'The URL format is invalid.',
      itemNumberExists: 'This item number is already in use and each one must be unique.',
      phoneNumberExists: 'The value is already used and must be unique',
      emailExists: 'The value is already used and must be unique',
      serverSidePhoneNumber: 'Invalid phone number format',
      auctionSlugExists: 'This URL is already used by another auction. Please try another one.',
      uniqueUserPhoneNumber: 'This phone number is already in use on another account. Please enter a new phone number.'
    },
    custom: {
      password: {
        regex: 'Your password must have at least 6 characters, a lowercase character, an uppercase character, a digit and a special character among the following: @$!%*?&.'
      },
      newPassword: {
        regex: 'Your password must have at least 6 characters, a lowercase character, an uppercase character, a digit and a special character among the following: @$!%*?&.'
      },
      emailSignup: {
        emailExists: 'This email is already connected to an account.'
      },
      phoneNumberSignup: {
        phoneNumberExists: 'This phone number is already connected to an account.'
      },
      invitation_input: {
        usernameExistsForAuction: 'This input is in the wrong format or a user/invitation already exists for this email/phone number.'
      },
      auctionSlug: {
        regex: 'This URL contains unauthorized characters. Please try another one.',
        excluded: 'This URL is reserved. Please try another one.'
      },
      cloneNumber: {
        excluded: 'This item number is already in use and each one must be unique.'
      }
    }
  },
  'fr-CA': {
    messages: {
      _default: 'Valeur invalide.',
      required: () => 'Ce champ est obligatoire.',
      email: () => 'Adresse courriel invalide.',
      min_value: () => `Entrez un nombre plus élevé.`,
      max_value: () => `Entrez un nombre plus bas.`,
      not_in: () => 'Ce champ est obligatoire.',
      numeric: () => 'Veuillez entrer un nombre entier positif.',
      phone: () => 'Le format du numéro de téléphone n’est pas valide.',
      postalCode: () => 'Veuillez entrer un code postal valide.',
      falsy: () => 'La valeur doit être non',
      min: (field, [length]) => `Le champ ${field} doit contenir au moins ${length} caractères.`,
      max: (field, [length]) => `Le champ ${field} doit contenir au maximum ${length} caractères.`,
      integer_if: () => 'Les valeurs fractionnelles ne sont pas acceptées pour ce produit',
      confirmed: 'La valeur des deux champs n\'est pas identique',
      duplicate: (field) => `La valeur de ${field} est déjà utilisée et doit être unique`,
      url: 'Le format de l\'URL n’est pas valide.',
      itemNumberExists: 'Ce numéro de lot est déjà utilisé et chacun doit être unique.',
      phoneNumberExists: 'La valeur est déjà utilisée et doit être unique',
      emailExists: 'La valeur est déjà utilisée et doit être unique',
      serverSidePhoneNumber: 'Le format du numéro de téléphone n’est pas valide',
      auctionSlugExists: 'Cette URL est déjà utilisée par un autre encan. Veuillez en essayer un autre.',
      uniqueUserPhoneNumber: 'Ce numéro de téléphone est déjà utilisé par un autre compte. Veuillez en choisir un autre.'
    },
    custom: {
      password: {
        regex: 'Veuillez entrer au moins 6 caractères incluant une lettre majuscule, un chiffre et un caractère spécial parmi les suivants : (@$!%*?&).'
      },
      newPassword: {
        regex: 'Veuillez entrer au moins 6 caractères incluant une lettre majuscule, un chiffre et un caractère spécial parmi les suivants : (@$!%*?&).'
      },
      emailSignup: {
        emailExists: 'Ce courriel est déjà connecté à un autre compte.'
      },
      phoneNumberSignup: {
        phoneNumberExists: 'Ce numéro de téléphone est déjà connecté à un autre compte.'
      },
      invitation_input: {
        usernameExistsForAuction: 'Cette entrée est dans un mauvais format ou un utilisateur/invitation existe déjà avec ce courriel/numéro de téléphone.'
      },
      auctionSlug: {
        regex: 'Cette URL contient des caractères non autorisés. Veuillez en essayer un autre.',
        excluded: 'Cette URL est réservée. Veuillez en essayer un autre.'
      },
      cloneNumber: {
        excluded: 'Ce numéro de lot est déjà utilisé et chacun doit être unique.'
      }
    }
  }
};
